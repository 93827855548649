@import "../../Breakpoints";

.mainFooter {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 0;
  padding-bottom: 30%;

  @include media-breakpoint('m') {
    padding-bottom: 100%;
  }
}

.footerInner {
  text-align: center;
}

.social {
  margin-top: 15px;
  display: flex;
  justify-content: center;
  align-items: center;

  a {
    display: block;
    transition: transform .3s;

    &:hover {
      transform: translateY(-2px);
    }
  }

  @include media-breakpoint('m') {
    padding-bottom: 180px;
  }
}

.icon {
  height: 50px;
  margin: 0 5px;
}

.footerImg img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: grayscale(1);
  opacity: 0.7;
  pointer-events: none;

  -webkit-mask-image: linear-gradient(to bottom, transparent 10%, black 80%);
  mask-image: linear-gradient(to bottom, transparent 10%, black 80%);
}