@import "../../Breakpoints";

.imageHolder {
  width: 100%;
  padding-bottom: 45%;
  position: relative;
  margin-bottom: 100px;

  @include media-breakpoint('xxl') {
    padding-bottom: 55%;
  }

  @include media-breakpoint('xl') {
    padding-bottom: 60%;
  }

  @include media-breakpoint('l') {
    padding-bottom: 65%;
  }

  @include media-breakpoint('m') {
    padding-bottom: 80%;
    margin-bottom: 200px;
  }

  @include media-breakpoint('m') {
    margin-bottom: 700px;
  }
}

.swiper {
  width: 100%;
  height: 100%;
  position: absolute !important;
  top: 0;
  left: 0;
  z-index: 1;
}

.image img {
  position: absolute;
  object-fit: cover;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

.textHolder {
  position: absolute;
  top: 170px;
  left: 0;
  right: 0;
  z-index: 10;

  @include media-breakpoint('m') {
    top: 200px;
  }
}

.textInner {
  background: rgba(14, 14, 14, .9);
  padding: 80px 30px 50px;
  max-width: 45%;
  font-size: 20px;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.7);
  position: relative;

  @include media-breakpoint('xxl') {
    max-width: 55%;
  }

  @include media-breakpoint('xl') {
    max-width: 65%;
    font-size: 18px;
  }

  @include media-breakpoint('m') {
    padding: 80px 30px 50px;
    font-size: 18px;
    max-width: 100%;
  }
}

.textHeader {
  position: absolute;
  top: -60px;
  right: 30px;
  font-size: 42px;
  display: flex;

  @include media-breakpoint('s') {
    font-size: 32px;
    top: -35px;
    right: 15px;
  }
}

.textTitle {
  font-family: Dreamwalker, sans-serif;
  font-size: 90px;

  @include media-breakpoint('s') {
    font-size: 65px;
  }
}

.textPreTitle {
  font-weight: bold;
  padding: 8px 10px 0 0;
}

.switchEn,
.textEn {
  display: none;

  &.isVisible {
    display: block;
  }
}

.switchDe,
.textDe {
  display: block;

  &.isHidden {
    display: none;
  }
}

.switchHolder {
  display: flex;
  justify-content: flex-end;
}

.switch {
  margin-top: 50px;
  text-align: right;
  font-family: Trashbox, sans-serif;
  cursor: pointer;
  transition: color .3s, background .3s;
  display: inline-block;
  padding: 2px 5px;

  &:hover {
    color: var(--color-black);
    background: var(--color-white);
  }
}