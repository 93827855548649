@import "../../Breakpoints";

.events {

}

.eventRows {}

.event {
  display: block;
}

.pastTable,
.table {
  width: 100%;
  font-size: 26px;
  border-spacing: 0;
  font-family: Dreamwalker, sans-serif;
  margin-top: 50px;

  th {
    text-transform: uppercase;
    border-bottom: 2px solid var(--color-white);
    padding: 20px;

    @include media-breakpoint('m') {
      display: none;
    }
  }

  td {
    padding: 10px 20px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    transition: background .5s;
  }

  tr:last-child td {
    border: none;
  }

  tr:first-child td {
    padding-top: 20px;
  }

  tr:hover {
    td {
      background: rgba(0, 0, 0, 0.4);
    }
  }
}

.table {
  margin-bottom: 50px;
}

.pastTable {
  margin-top: 10px;
}

.pastWrapper {
  opacity: 0.7;
  transition: opacity .3s;
  -webkit-mask-image: linear-gradient(to top, transparent 10%, black 80%);
  mask-image: linear-gradient(to top, transparent 10%, black 80%);
}

.pastWrapperLoaded {
  opacity: 0.7;
  transition: opacity .3s;
}


.titleDate,
.titleVenue,
.titleLocation {
  text-align: left;
}


.titleVenue,
.titleLocation {
  padding-left: 40px !important;
}

.location {
  @include media-breakpoint('m') {
    font-size: 18px;
    line-height: 32px;
  }
}

.date{
 @include media-breakpoint('m') {
   font-size: 20px;
   line-height: 32px;
 }
}

.titleTickets {
  text-align: center;
}

.eventRow {
  &.cancelled {
    opacity: 0.3;
  }

  @include media-breakpoint('m') {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    width: 100%;

    td {
      flex-basis: 50%;
      padding: 0 20px !important;
    }

    td:first-child {
      border: 0;
      flex-basis: 100%;
      padding-top: 20px !important;
    }

    td:nth-child(2) {
      flex-basis: 100%;
      border: 0;
    }

    td:nth-child(3) {
      flex-basis: 60%;
      border: 0;
    }

    td:nth-child(4) {
      flex-basis: 40%;
    }
  }
}

.loadingMore,
.loadMore,
.ticket {
  color: var(--color-black);
  width: 100%;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  border: 2px solid var(--color-white);
  transition: all .3s;
  position: relative;

  &.cancelled,
  &.soldOut {
    background: var(--color-red);
    color: var(--color-white);
    border: 2px solid var(--color-red);
    pointer-events: none;

    &:before {
      display: none;
    }
  }

  &:before,
  &:after {
    display: block;
    content: '';
    position: absolute;
    top: 100%;
    left: 0;
    bottom: 0;
    width: 100%;
    background: var(--color-black);
    transition: top .3s;
  }

  &:before {
    background: var(--color-white);
    top: 0;
  }

  &:hover {
    cursor: pointer;
    color: var(--color-white);

    &:after {
      top: 0;
    }
  }

  a {
    text-decoration: none;
  }

  @include media-breakpoint('m') {
    margin: 20px 0 30px;
    font-size: 22px;
    height: 40px;
  }
}

.ticketText {
  z-index: 10;
}

.loadMore {
  font-family: Dreamwalker, sans-serif;
  max-width: 250px;
  margin: 10px auto;
  transition: opacity .3s;
}

.loadingMore {
  font-family: Dreamwalker, sans-serif;
  max-width: 250px;
  margin: 10px auto;
  transition: opacity .3s;
  opacity: 0.5;
  pointer-events: none;
}