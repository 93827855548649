@import "../../Breakpoints";

$site-cols: 3;
$gap: 5px;

.itemsWrapper {
  display: grid;
  grid-auto-flow: dense;
  grid-gap: $gap;
  row-gap:  $gap;
  grid-template-columns: repeat(4, 1fr);
  margin: 50px 0 0;

  @include media-breakpoint('m') {
    margin: 50px 0 100px;
  }
}

.item {
  position: relative;
  overflow: hidden;
  height: 0;
  padding-bottom: calc(100% - 10px);
  grid-column-start: span 1;
  grid-row-start:    span 1;
  margin: 5px;

  @include media-breakpoint('m') {
    grid-column-start: span 2;
    grid-row-start:    span 2;
    padding-bottom: calc(100%);
  }
}

.item.rectangle {
  grid-column-start: span 2;
  grid-row-start:    span 1;
  padding-bottom: calc(50% - 10px);

  @include media-breakpoint('m') {
    grid-column-start: span 4;
    grid-row-start:    span 2;
    padding-bottom: calc(50%);
  }
}

.item.video {
  position: relative;
  height: auto;
  width: 100%;
  padding: 0;
  grid-column-start: span 4;
  grid-row-start:    span 3;
  margin: 5px;

  @include media-breakpoint('m') {
    width: auto;
  }
}

.itemImage img {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  object-position: center;
  object-fit: cover;
  filter: grayscale(100%);
  transition: transform .6s, filter .6s;
  transform: scale(1);

  &:hover {
    cursor: pointer;
    filter: grayscale(0%);
    transform: scale(1.015);
  }
}

.itemVideo {
  width: 100%;
  height: auto;
  background: var(--color-black-outer);
}

.normalFont {
  font-family: 'Oswald', sans-serif;
}

.videoText {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 15px 0 50px;

  @include media-breakpoint('m') {
    justify-content: center;
  }
}

.videoIcon {
  width: 30px;
  margin: 0 10px -3px 0;
  transition: fill .3s;

  @include media-breakpoint('m') {
    margin: 0 10px -5px 0;
  }
}

.videoLink {
  border: 2px solid var(--color-white);
  font-size: 24px;
  font-family: Dreamwalker, sans-serif;
  margin: 10px 0;
  cursor: pointer;
  position: relative;
  transition: color .3s;
  padding: 5px 10px;
  z-index: 100;

  &:after {
    display: block;
    content: '';
    width: 100%;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 100%;
    background: var(--color-white);
    transition: top .3s;
    z-index: -1;
  }

  &:hover {
    color: var(--color-black);

    &:after {
      top: 0;
    }

    .videoIcon {
      fill: var(--color-black);
    }
  }

  @include media-breakpoint('m') {
    font-size: 20px;
  }
}

.videoInner {
  z-index: 10;
}

.yt_videos {
  display: flex;
  gap: 15px;
  margin-top: 10px;

  @include media-breakpoint('m') {
    flex-direction: column;

    iframe {
      width: 100%;
    }
  }
}