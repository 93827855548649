.mainContent {
  background: var(--color-black);
  background: linear-gradient(90deg, var(--color-black-outer) 0%, var(--color-black) 30%, var(--color-black) 70%, var(--color-black-outer) 100%);
  color: var(--color-white);
  width: 100%;
  padding-top: 86px;
}

.text {
  font-size: 22px;
  word-break: break-word;
  hyphens: auto;
}