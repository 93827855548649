.loader {
  position: absolute;
  top: calc(50% - 100px);
  left: calc(50% - 75px);
  width: 150px;
  height: 150px;
  border-radius: 50%;
  perspective: 800px;
}

.inner {
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.inner:first-child {
  left: 0;
  top:  0;
  animation: rotate-one 2s linear infinite;
  border-bottom: 3px solid #EFEFFA;
}

.inner:nth-child(2) {
  left: 0;
  top:  0;
  animation: rotate-two 2s linear infinite;
  border-right: 3px solid #EFEFFA;
}

.inner:last-child {
  left: 0;
  top:  0;
  animation: rotate-three 2s linear infinite;
  border-top: 3px solid #EFEFFA;
}

@keyframes rotate-one {
  0% {
    transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
  }
  100% {
    transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
  }
}

@keyframes rotate-two {
  0% {
    transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
  }
  100% {
    transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
  }
}

@keyframes rotate-three {
  0% {
    transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
  }
  100% {
    transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
  }
}


.loaderWrapper {
  width:  100vw;
  height: 100vh;
}