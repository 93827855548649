.page404 {
  padding: 200px 0 400px;
  display: flex;
  align-items: center;
  font-family: Dreamwalker, sans-serif;
}

.title {
  font-size: 188px;
}

.text {
  font-size: 38px;
}