@import "../../Breakpoints";

.downloads {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 30px;

  @include media-breakpoint('l') {
    gap: 15px;
  }

  @include media-breakpoint('m') {
    gap: 15px;
  }

  @include media-breakpoint('xs') {
    gap: 0;
  }
}

.download {
  flex-basis: calc(25% - 30px);
  background: var(--color-black-outer);
  padding: 20px 15px 15px;
  transition: transform .3s;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  &:hover {
    transform: translateY(-5px);
  }

  @include media-breakpoint('l') {
    flex-basis: calc(33.33% - 15px);
  }

  @include media-breakpoint('m') {
    flex-basis: calc(50% - 15px);
    margin-bottom: 20px;
  }

  @include media-breakpoint('xs') {
    flex-basis: calc(100%);
  }
}

.title {
  font-size: 22px;
  margin-top: auto;
  background: white;
  color: black;
  padding: 5px;
}

.imageHolder {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.image img {
  width: 100%;
}

.zip {
  width: 50px;
  fill: var(--color-white);
}