@import "../../Breakpoints";

.header {
  height: 86px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 120;
  transition: all .5s;

  background: var(--color-black);
  background: linear-gradient(90deg, var(--color-black-outer) 0%, var(--color-black) 30%, var(--color-black) 70%, var(--color-black-outer) 100%);
  opacity: 0.95;

  &.isScrolled {
    box-shadow: 0 5px 20px rgba(0,0,0,0.2);
  }

  > div,
  > div > div {
    height: 100%;
  }
}

.title {
  font-size: 42px;
  font-family: 'Dreamwalker', 'sans-serif';
  text-decoration: none;
}

.navigation {
  height: 100%;
  display: flex;
  justify-content: flex-end;
  vertical-align: center;
  text-transform: uppercase;
  font-size: 22px;
  font-family: Dreamwalker, sans-serif;

  @include media-breakpoint('m') {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.7);
    backdrop-filter: blur(17px);
    flex-direction: column;
    justify-content: flex-start;
    padding: 80px 0;
    opacity: 0;
    pointer-events: none;
    transition: all .5s;

    &.isOpen {
      opacity: 1;
      pointer-events: auto;

      .navItem {
        opacity: 1;
        margin-left: 15px;
      }
    }
  }
}

.navItem {
  margin: 0 15px;
  text-decoration: none;
  position: relative;
  padding: 5px 15px;
  transition: color .3s;

  @include media-breakpoint('m') {
    padding: 15px;
    font-size: 28px;
    transition: opacity .3s, margin-left .3s;
    margin-left: -10px;
    opacity: 0;
  }

  &:before {
    display: block;
    content: '';
    width: 100%;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 100%;
    background: var(--color-white);
    transition: top .3s;
    z-index: -1;
  }

  @include media-breakpoint($from: 'm') {
    &:hover {
      color: var(--color-black);

      &:before {
        top: 0;
      }
    }
  }
}

.headerCol {
  padding: 0 !important;
}

.headerRow {
  margin: 0 -15px !important;
}

.burger {
  display: none;
  position: relative;
  margin-left: auto;
  width:  28px;
  height: 20px;

  @include media-breakpoint('m') {
    display: block;
  }
}

.burger.isOpen {
  .burgerLine:nth-child(1) {
    transform: rotate(45deg);
  }

  .burgerLine:nth-child(3) {
    transform: rotate(-45deg);
  }
}


.burger span {
  display: block;
  position: absolute;
  width: 100%;
  height: 2px;
  background: var(--color-white);
  transition: all .3s;
  left: 0;

  &:nth-child(1) {
    top: 0;
  }

  &:nth-child(2) {
    bottom: calc(50% - 1px);
    width: 75%;
    left: auto;
    right: 0;
  }

  &:nth-child(3) {
    bottom: 0;
  }
}

.burger.isOpen {
  span:first-child {
    transform: rotate(45deg);
    top: 9px;
  }
  span:nth-child(2) {
    height: 0;
    width: 0;
  }
  span:nth-child(3) {
    transform: rotate(-45deg) ;
    bottom: 9px;
  }
}

.hasOpenMenu {
  filter: blur(17px);
  transition: transform .6s, filter .6s;
}