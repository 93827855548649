@import "../../Breakpoints";

.pageIntro {
  max-width: 1600px;
  margin: 0 auto 70px;
  min-height: calc(100vh - 90px);
  height: calc(100vh - 90px);

  @include media-breakpoint('m') {
    margin: 20px auto 0;
    overflow: hidden;
    padding: 30px 0 80px;
  }
}

.imageHolder {
  position: relative;
  height: 100%;

  @include media-breakpoint('m') {
    height: auto;
  }
}

@keyframes fadeUp {
  0% {
    transform: translateY(15px) scale(1.025);
    opacity: 0;
  }
  100% {
    transform: translateY(0) scale(1);
    opacity: 1;
  }
}

.image {
  height: 100%;
  width: 100%;
  object-fit: contain;
  -webkit-mask-image: linear-gradient(to top, transparent 10%, black 80%);
  mask-image: linear-gradient(to top, transparent 10%, black 80%);
  animation: 3.5s ease-out 0s 1 fadeUp;

  @include media-breakpoint('m') {
    width: 120%;
    height: auto;
    object-fit: initial;
    margin: 0 -10%;
  }
}

@keyframes zoomEffect {
  0% {
    opacity: 0;
    transform: translateY(10px) scale(1.2, 1.2);
  }
  100% {
    opacity: 1;
    transform: translateY(0) scale(1.2, 1.2);
  }
}

.logo {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  max-width: 55%;
  margin: 0 auto;

  @include media-breakpoint('m') {
    max-width: 90%;
    margin-bottom: -70px;
  }
}




.scroller {
  margin: 130px auto 0;
  z-index: 9999;
  display: none;

  @include media-breakpoint('m') {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
}

.scroller span{
  display: block;
  width: 15px;
  height: 15px;
  transform: rotate(45deg);
  border-right: 2px solid #fff;
  border-bottom: 2px solid #fff;
}
.scroller .mouse {
  height: 45px;
  width: 25px;
  border-radius: 20px;
  transform: none;
  border: 2px solid #ffffff;
  top: 170px;
}
.scroller .downArrow1 {
  margin-top: 6px;
}
.scroller .downArrow1, .scroller .downArrow2, .scroller .downArrow3 {
  -webkit-animation: mouse-scroll 2s infinite;
  -moz-animation: mouse-scroll 2s infinite;
}
#mouse-croll .downArrow1 {
  -webkit-animation-delay: .1s;
  -moz-animation-delay: .1s;
}
.scroller .downArrow2 {
  animation-delay: .2s;
}
.scroller .downArrow3 {
  animation-delay: .3s;
}
.scroller .mouseIn {
  height: 10px;
  width: 2px;
  display: block;
  margin: 5px auto;
  background: #ffffff;
  position: relative;
  -webkit-animation: animated-mouse 1.2s ease infinite;
  moz-animation: mouse-animated 1.2s ease infinite;
}


@keyframes animated-mouse {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(12px);
  }
}

@keyframes mouse-scroll {
  0% {
    opacity: 0;
  }
  70% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}