.imageHolder {
  padding: 15px;
}

.imageText {
  margin: 0 auto;
  max-width: 1000px;
  overflow: hidden;
}

.text {
  font-size: 20px;
  white-space: break-spaces;
  margin: 50px 0;
}