@import '../../Breakpoints';

.form {
  margin: 50px auto;
  max-width: 1000px;
  transition: opacity .3s;

  &.isLoading {
    opacity: 0.4;
  }

  &.isVisible {
    display: none;
    opacity: 1;
  }
}

.oInput {
  position: relative;


  &.hasError {
    .oInputField {
      border-color: var(--color-red) !important;
    }
    .oInputCheckboxLabel {
      color: var(--color-red) !important;
    }
  }
}

.oInputField {
  font-family: 'Oswald', sans-serif;
  font-size: 20px;
  color: var(--color-white);
  display: block;
  width: 100%;
  padding: 7px 10px 7px 10px;
  appearance: none;
  border-radius: 2px;
  background: transparent;
  border: 2px solid var(--color-white);
  transition: border .2s;
  margin: 0 0 10px;

  &:focus {
    outline: none;
    border-color: var(--color-white);
  }
}

.oInputField:disabled {
  background: #d2d2d2;
  pointer-events: none;
}

.oInputField:disabled + .oInputLabel {
  color: #d2d2d2;
}

.oInputCheckboxLabel,
.oInputLabel {
  cursor: text;
  color: var(--color-white);
  user-select: none;
  transform: translateY(18px);
  transition: all .4s;
}

.oInputCheckboxLabel {
  display: flex;
  align-items: center;

  input {
    margin-right: 10px;
  }

  a {
    margin: 0 5px;
  }

  @include media-breakpoint('m') {
    margin-bottom: 20px;
    display: block;
  }
}

.oButton {
  font-family: Dreamwalker, sans-serif;
  font-size: 26px;
  background: none;
  color: var(--color-white);
  width: 100%;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  border: 2px solid var(--color-white);
  transition: all .3s;
  margin-top: 20px;
  font-weight: 400;
  border-radius: 0 !important;
  -webkit-appearance: none;
  position: relative;
  z-index: 1;
  text-decoration: none;

  &:before {
    display: block;
    content: '';
    width: 100%;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 100%;
    background: var(--color-white);
    transition: top .3s;
    z-index: -1;
  }

  @include media-breakpoint($from: 'm') {
    &:hover {
      color: var(--color-black);
      cursor: pointer;

      &:before {
        top: 0;
      }
    }
  }
}

.messageHolder {
  display: flex;
  justify-content: flex-end;
}

.message {
  margin-top: 10px;
  padding: 10px;
  background: var(--color-red);
  color: var(--color-white);
  margin-left: auto;
  display: none;

  &.isVisible {
    display: inline-block;
  }
}

.success {
  font-size: 32px;
  font-family: Trashbox, sans-serif;
  text-align: center;
  opacity: 0;
  display: none;
  transition: opacity .3s;
  padding: 150px 0;

  &.isVisible {
    display: block;
    opacity: 1;
  }
}

.subtext {
  font-family: 'Oswald', sans-serif;
  font-size: 24px;
}

.successIcon {
  height: 75px;
  fill: var(--color-white);
  margin-bottom: 25px;
}