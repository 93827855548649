body {
  margin: 0;
  font-family: 'Oswald', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


a {
  color: inherit;
  text-decoration: underline;
}

img {
  width: 100%;
  height: auto;
}




/*===============*/
/*    FONTS      */
/*===============*/

@font-face {
  font-family: 'Dreamwalker';
  src: local('Dreamwalker'), url(./assets/fonts/Dreamwalker.woff) format('woff');
}

@font-face {
  font-family: 'Trashbox';
  src: local('Trashbox'), url(./assets/fonts/Trashbox.woff) format('woff');
}

/* oswald-200 - latin */
@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 200;
  src: url('./assets/fonts/oswald-v48-latin-200.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('./assets/fonts/oswald-v48-latin-200.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('./assets/fonts/oswald-v48-latin-200.woff2') format('woff2'), /* Super Modern Browsers */
  url('./assets/fonts/oswald-v48-latin-200.woff') format('woff'), /* Modern Browsers */
  url('./assets/fonts/oswald-v48-latin-200.ttf') format('truetype'), /* Safari, Android, iOS */
  url('./assets/fonts/oswald-v48-latin-200.svg#Oswald') format('svg'); /* Legacy iOS */
}
/* oswald-300 - latin */
@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 300;
  src: url('./assets/fonts/oswald-v48-latin-300.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('./assets/fonts/oswald-v48-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('./assets/fonts/oswald-v48-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
  url('./assets/fonts/oswald-v48-latin-300.woff') format('woff'), /* Modern Browsers */
  url('./assets/fonts/oswald-v48-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
  url('./assets/fonts/oswald-v48-latin-300.svg#Oswald') format('svg'); /* Legacy iOS */
}
/* oswald-regular - latin */
@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/oswald-v48-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('./assets/fonts/oswald-v48-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('./assets/fonts/oswald-v48-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('./assets/fonts/oswald-v48-latin-regular.woff') format('woff'), /* Modern Browsers */
  url('./assets/fonts/oswald-v48-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('./assets/fonts/oswald-v48-latin-regular.svg#Oswald') format('svg'); /* Legacy iOS */
}
/* oswald-500 - latin */
@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 500;
  src: url('./assets/fonts/oswald-v48-latin-500.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('./assets/fonts/oswald-v48-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('./assets/fonts/oswald-v48-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
  url('./assets/fonts/oswald-v48-latin-500.woff') format('woff'), /* Modern Browsers */
  url('./assets/fonts/oswald-v48-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
  url('./assets/fonts/oswald-v48-latin-500.svg#Oswald') format('svg'); /* Legacy iOS */
}
/* oswald-600 - latin */
@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 600;
  src: url('./assets/fonts/oswald-v48-latin-600.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('./assets/fonts/oswald-v48-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('./assets/fonts/oswald-v48-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
  url('./assets/fonts/oswald-v48-latin-600.woff') format('woff'), /* Modern Browsers */
  url('./assets/fonts/oswald-v48-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
  url('./assets/fonts/oswald-v48-latin-600.svg#Oswald') format('svg'); /* Legacy iOS */
}
/* oswald-700 - latin */
@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 700;
  src: url('./assets/fonts/oswald-v48-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('./assets/fonts/oswald-v48-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('./assets/fonts/oswald-v48-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
  url('./assets/fonts/oswald-v48-latin-700.woff') format('woff'), /* Modern Browsers */
  url('./assets/fonts/oswald-v48-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
  url('./assets/fonts/oswald-v48-latin-700.svg#Oswald') format('svg'); /* Legacy iOS */
}