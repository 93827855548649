:root {
  /* COLORS */
  --color-white: #ffffff;
  --color-black: #141414;
  --color-black-outer: #0A0A0A;
  --color-gray--light: #D9D9D9;
  --color-red: #9F2D2D;

  /* FONT WEIGHT */
  --font-weight--light: 300;
  --font-weight--normal: 400;
  --font-weight--bold: 700;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

body {
  background: var(--color-black);
  background: linear-gradient(90deg, var(--color-black-outer) 0%, var(--color-black) 30%, var(--color-black) 70%, var(--color-black-outer) 100%);
  color: var(--color-white);
}

